import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import "./assets/scss/index.scss";
import Shell from "./shell/Shell";
import { AppConfig, getAppConfig } from "./common/utils";
import "./i18n/translation";
import { AppService } from "./services/app.services";
let appConfig: AppConfig;
AppService.axios
    .get(`${process.env.PUBLIC_URL}config/config.json?v=${process.env.REACT_APP_VERSION}`)
    .then((appconfig) => {
        window.appConfig = appconfig.data as AppConfig;
        appConfig = getAppConfig();
        if (appConfig.analytics.trackingEnabled === "true") {
            initCookieConsent().then(() => {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                function OptanonWrapper() {}
                TagManager.initialize(appConfig.analytics.props);
            });
        }
        ReactDOM.render(
            <React.StrictMode>
                <Shell />
            </React.StrictMode>,
            document.getElementById("root"),
        );
        if (appConfig.monitoring?.enabled === "true" && appConfig.monitoring?.props?.scriptSrc) {
            initMonitoring().then(() => {
                const MONITORING_CONFIG = {
                    clientToken: appConfig.monitoring.props.clientToken,
                    applicationId: appConfig.monitoring.props.applicationId,
                    site: appConfig.monitoring.props.site,
                    service: appConfig.monitoring.props.service,
                    env: appConfig.monitoring.props.environment,
                    version: process.env.REACT_APP_VERSION,
                    sampleRate: 100,
                    sessionReplaySampleRate: 100, // if not included, the default is 100
                    trackResources: true,
                    trackLongTasks: true,
                    trackInteractions: true,
                };
                if (window.DD_RUM) {
                    window.DD_RUM.init?.(MONITORING_CONFIG);
                    window.DD_RUM.startSessionReplayRecording?.();
                }
            });
        }
    });

/**
 * Method to inject the OneTrust cookie consent banner script for the given environment
 * @returns Promise
 */
function initCookieConsent() {
    return new Promise((resolve) => {
        const HEAD_TAG = document.getElementsByTagName("head")[0];
        const COOKIE_SCRIPT_ELE = document.createElement("script");
        COOKIE_SCRIPT_ELE.type = "text/javascript";
        COOKIE_SCRIPT_ELE.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        COOKIE_SCRIPT_ELE.setAttribute("data-domain-script", appConfig.cookieConsent.domainScriptId);
        HEAD_TAG.appendChild(COOKIE_SCRIPT_ELE);
        resolve([]);
    });
}
function initMonitoring() {
    const appConfig = getAppConfig();
    return new Promise((resolve, reject) => {
        const HEAD_TAG = document.getElementsByTagName("head")[0];
        const MONITORING_SCRIPT_ELE = document.createElement("script");
        MONITORING_SCRIPT_ELE.type = "text/javascript";
        MONITORING_SCRIPT_ELE.src = appConfig.monitoring.props.scriptSrc;
        MONITORING_SCRIPT_ELE.onload = () => {
            resolve([]);
        };
        MONITORING_SCRIPT_ELE.onerror = () => {
            reject();
        };
        HEAD_TAG.appendChild(MONITORING_SCRIPT_ELE);
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
