import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { IAioApps, IApiStatus } from "../../../../common/models";
import Dropdown, { IDropdownItem } from "../Dropdown/Dropdown";
import { AppContext } from "../../../../context/app.context";
import { AppConfig, getAppConfig, parseLangCode } from "../../../../common/utils";
import "./AppCard.scss";
import defaultImage from "../../../../assets/images/ic_app_default.svg";

export interface AppCardProps {
    appData: IAioApps;
}

function AppCard(props: AppCardProps & IApiStatus) {
    const { t } = useTranslation();
    const [dropdownData, setDropdownData] = useState([] as IDropdownItem[]);
    const appContext = useContext(AppContext);
    const config: AppConfig = getAppConfig();
    const openContactForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, appData: IAioApps) => {
        e?.preventDefault();
        const { firstName, lastName, language } = appContext.state.user;
        const email = appContext.state.user.email;
        const appName = appData.metaData?.displayName || appData.name;
        const lang = parseLangCode(language);
        if (email) {
            const dEmail = email.split("@");
            const cName = dEmail[1].split(".");
            let appContent = t("TTM.apps.requestForDemoContent", { appName: appName });
            appContent = appContent.replace("&", "%26");
            let formURL = `${config.contactLink}?email=${email}&firstname=${firstName}&lastname=${lastName}&company=${cName[0]}&language=${lang}`;
            formURL = encodeURI(formURL);
            formURL = formURL.replace("+", "%2B");
            formURL = `${formURL}&message=${appContent}`;
            window.open(formURL, "_blank", "noreferrer");
        }
    };
    useEffect(() => {
        const appDropdownItems: IDropdownItem[] = [];
        if (props.appData.metaData && props.appData.metaData.options) {
            const productMetadataOptions = props.appData.metaData.options;
            for (const key in productMetadataOptions) {
                if (key) {
                    const dropDownItem: IDropdownItem = {} as IDropdownItem;
                    dropDownItem.displayName = key;
                    dropDownItem.clickable = true;
                    dropDownItem.link = productMetadataOptions[key];
                    appDropdownItems.push(dropDownItem);
                }
            }
            setDropdownData(appDropdownItems);
        }
    }, [props.appData]);

    return (
        <>
            <div data-test="component-appCard" className="appCard mb-3 mr-2 ml-2">
                <a
                    href={props.appData.metaData?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex cursor-pointer text-decoration-none w-100 h-100"
                >
                    <div className="card shadow-1">
                        <div className="card-body">
                            <h5 className="card-subtitle d-flex justify-content-between">
                                <div className="d-flex appDisplay align-items-center">
                                    {!props.isApiLoading ? (
                                        <img
                                            alt={`${props.appData.metaData?.displayName ?? props.appData.name}-logo`}
                                            className="logoIcon d-flex cursor-pointer"
                                            src={
                                                props.appData.metaData?.icon
                                                    ? `${props.appData.metaData.icon}`
                                                    : defaultImage
                                            }
                                            onError={(e) => (e.currentTarget.src = defaultImage)}
                                        />
                                    ) : (
                                        <Skeleton circle={true} height={35} width={35} />
                                    )}

                                    {!props.isApiLoading ? (
                                        <span
                                            data-test="app-name"
                                            className="d-flex cursor-pointer text-decoration-none appName"
                                        >
                                            {props.appData.metaData?.displayName ?? props.appData.name}
                                        </span>
                                    ) : (
                                        <p className="pl-3 pt-3">
                                            <Skeleton height={14} width={100} />
                                        </p>
                                    )}
                                </div>
                                {!props.isApiLoading
                                    ? dropdownData.length > 0 && (
                                          <div
                                              data-test="app-options"
                                              className="dropdownHolder d-flex float-right"
                                              onClick={(e) => e.preventDefault()}
                                          >
                                              <Dropdown dropDownItems={dropdownData} parentIcon="more_vertical" />
                                          </div>
                                      )
                                    : null}
                            </h5>
                            <p data-test="app-description" className="card-text">
                                {!props.isApiLoading ? (
                                    props.appData.metaData?.description ? (
                                        t(`metaData:${props.appData.metaData?.description}`)
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    <Skeleton count={3} />
                                )}
                            </p>
                            {!props.isApiLoading ? (
                                <div>
                                    <div
                                        data-test="app-action-button"
                                        className={`appActionBtn btn float-right btn-outline-primary ${
                                            !props.appData.hasAccess ? "btnDemo" : ""
                                        }`}
                                        onClick={(e) =>
                                            !props.appData.hasAccess ? openContactForm(e, props.appData) : () => false
                                        }
                                    >
                                        {props.appData.hasAccess ? (
                                            <span data-test="app-demo" className="blue-text">
                                                {t("TTM.apps.options.open")}
                                            </span>
                                        ) : (
                                            <span data-test="app-demo">{t("TTM.apps.requestForDemo")}</span>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="float-right w-25">
                                        <Skeleton />
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </a>
            </div>
        </>
    );
}

export default AppCard;
