import { AppState } from "./app.model";
import { ActionTypes, SET_LOGIN, SET_USER, SET_ACCESS_TOKEN, SET_AUTH_SERVICE } from "./action";

const appReducer = (state: AppState, action: ActionTypes): AppState => {
    switch (action.type) {
        case SET_LOGIN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
            };
        case SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
            };
        case SET_AUTH_SERVICE:
            return {
                ...state,
                authService: action.authService,
            };
        default:
            return state;
    }
};
export default appReducer;
