export interface ILocale {
    label: string;
    value: string;
    docValue?: string;
}
/**
 * Updated the list here to match the list in marketing page (trimbletl.com) and included docValue to map
 * the langauges param that need to be replaced for documentation portal.
 */
export const LOCALE_LIST: ILocale[] = [
    { label: "Deutsch", value: "de", docValue: "de" },
    { label: "English", value: "en", docValue: "en" },
    { label: "Español", value: "es", docValue: "es" },
    { label: "Français", value: "fr", docValue: "fr" },
    { label: "Italiano", value: "it", docValue: "en" },
    { label: "Nederlands", value: "nl", docValue: "nl" },
    { label: "Polski", value: "pl", docValue: "pl" },
    { label: "Português", value: "pt", docValue: "pt-pt" },
    /*{ label: "Čeština", value: "cs" },
    { label: "Dansk", value: "da" },
    { label: "Русский", value: "ru" },
    { label: "Svenska", value: "sv" },*/
];
