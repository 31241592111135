import React from "react";
import loader from "./../../../../assets/images/loading.gif";
const Preloader: React.FC = () => {
    return (
        <div className="alignCenter">
            <img src={loader} alt="Preloader" />
        </div>
    );
};
export default Preloader;
