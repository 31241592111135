/* eslint-disable prettier/prettier */
import { UserManager, WebStorageStateStore, Log, User, InMemoryWebStorage } from "oidc-client";
import { AppConfig, getAppConfig } from "./../common/utils";

export default class AuthService {
    public userManager: UserManager;
    public appConfig: AppConfig;
    CLAIMS_REF = "http://wso2.org/claims/";
    constructor() {
        this.appConfig = getAppConfig();
        const IDENTITY_CONFIG = {
            authority: this.appConfig.identity.url,
            "client_id": this.appConfig.identity.client,
            "redirect_uri": this.appConfig.identity.redirect,
            "post_logout_redirect_uri": this.appConfig.identity.redirect,
            "response_type": this.appConfig.identity.response,
            scope: this.appConfig.identity.scope,
            loadUserInfo: false,
            revokeAccessTokenOnSignout: false,
        };
        const METADATA_OIDC = {
            issuer: this.appConfig.identity.url,
            "jwks_uri": this.appConfig.identity.url + "/.well-known/jwks.json",
            "authorization_endpoint": `${this.appConfig.identity.url}${this.appConfig.identity.authorizePath}`,
            "token_endpoint": this.appConfig.identity.url + "/oauth/token",
            "end_session_endpoint": `${this.appConfig.identity.url}${this.appConfig.identity.logoutUrl}`,
            "revocation_endpoint": `${this.appConfig.identity.url}/oauth/revoke`,
        };
        this.userManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
            metadata: {
                ...METADATA_OIDC,
            },
        });
    }
    public async getUser() {
        const usr: User = (await this.userManager.getUser()) as User;
        if (this.appConfig.identity.url.includes("identity")) {
            usr.profile.name = usr.profile[`${this.CLAIMS_REF}firstname`] +" " + usr.profile[`${this.CLAIMS_REF}lastname`];
            usr.profile.email = usr.profile[`${this.CLAIMS_REF}emailaddress`];
            usr.profile["given_name"] = usr.profile[`${this.CLAIMS_REF}firstname`];
            usr.profile["family_name"] = usr.profile[`${this.CLAIMS_REF}lastname`];
        } else {
            usr.profile.name = `${usr.profile.given_name} ${usr.profile.family_name}`;
        }
        return usr;
    }
    public login() {
        this.userManager.clearStaleState();
        window.localStorage.clear();
        let stateParam = "";
        if ((window.location.pathname && window.location.pathname !== "/")) {
            stateParam = encodeURIComponent(window.location.pathname+window.location.search);
        }
        this.userManager.signinRedirect({ state: stateParam });
    }
    public logout(idToken: string) {
        if (idToken) {
            this.userManager.signoutRedirect({ "id_token_hint": idToken });
            this.userManager.clearStaleState();
        }
    }
}
