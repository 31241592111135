import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/app.context";
import { SET_USER } from "../context/action";
import { AppService } from "../services/app.services";
import { parseLangCode } from "./../common/utils";
import Preloader from "../components/elements/atom/Preloader/Preloader";
import { User } from "../context/user.model";

const WithAutz = (props: any) => {
    const { state, dispatch } = useContext(AppContext);
    const [authzState, setAuthzState] = useState({
        isLoading: true,
    });

    /**
     * Method to retrieve USER details from the token
     */
    const checkUserProfile = async () => {
        if (state.authService) {
            state.authService
                .getUser()
                .then((user) => {
                    try {
                        const userObj: User = {
                            name: user?.profile?.name || "",
                            email: user?.profile?.email,
                            id: user?.profile?.sub || "",
                            idToken: user?.id_token || "",
                            firstName: user?.profile?.given_name,
                            lastName: user?.profile?.family_name,
                        };
                        userObj.language = parseLangCode(navigator.language);
                        if (localStorage.getItem("language")) {
                            userObj.language = localStorage.getItem("language") || "";
                        }
                        AppService.authorize(user?.access_token || "");
                        dispatch({ type: SET_USER, user: userObj });
                        setAuthzState({ isLoading: false });
                    } catch (error) {
                        console.log(error);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };
    const checkUser = () => {
        if (Object.entries(state.user).length === 0) {
            checkUserProfile();
        }
    };

    const initUserCheck = () => {
        if (authzState.isLoading) {
            checkUser();
        }
    };

    useEffect(initUserCheck, [authzState.isLoading]);

    if (authzState.isLoading) {
        return <Preloader />;
    }
    return <React.Fragment>{props.children}</React.Fragment>;
};

export default WithAutz;
