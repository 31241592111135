import React from "react";
import { CarouselProvider, Slider, Slide, DotGroup, Image } from "pure-react-carousel";
import Skeleton from "react-loading-skeleton";
import { IApiStatus } from "../../../../common/models";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Banner.scss";

export interface BannerProps {
    bannerImages: string[];
}

function Banner(props: BannerProps & IApiStatus) {
    return (
        <section data-test="component-banner" className="banner">
            <div className="carouselContainer">
                {!props.isApiLoading && props.bannerImages ? (
                    <CarouselProvider
                        totalSlides={props.bannerImages.length}
                        naturalSlideWidth={100}
                        naturalSlideHeight={100}
                        isPlaying={true}
                        interval={5000}
                    >
                        <div>
                            <Slider className="slider">
                                {props.bannerImages.length > 0 &&
                                    props.bannerImages.map((image, i) => (
                                        <Slide index={i} key={i}>
                                            <Image src={image} hasMasterSpinner={false} />
                                        </Slide>
                                    ))}
                            </Slider>
                        </div>
                        {props.bannerImages.length > 1 ? <DotGroup className="sliderDots" /> : ""}
                    </CarouselProvider>
                ) : (
                    <Skeleton height={350} />
                )}
            </div>
        </section>
    );
}

export default Banner;
