export interface IUmpErrResponse {
    error: string;
    message: string;
    path: string;
    status: number;
    timestamp: string;
}

export interface IApiStatus {
    isApiLoading: boolean;
}
export interface IAioAppOptions {
    [key: string]: string;
}
export interface IAioApps {
    name: string;
    options?: IAioAppOptions[];
    published?: boolean;
    hasAccess?: boolean;
    metaData?: any;
    private?: boolean;
}
export const SkeletonCardData: IAioApps[] = [
    {
        name: "",
    },
    {
        name: "",
    },
    {
        name: "",
    },
    {
        name: "",
    },
];
