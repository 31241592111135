import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Home from "../../pages/Home/Home";
import WithAuthz from "./../../../auth/Authorization";
import Header from "../../elements/organisms/Header/Header";

function AppLayout() {
    return (
        <div data-test="app-layout" className="modus-layout">
            <WithAuthz>
                <Header headerTitle="Customer Hub" />
                <div data-test="app-layout-body" className="modus-body" data-modus-item="body">
                    <div className="modus-content-rows">
                        <Switch>
                            <Route path="/home" exact component={Home} />
                            <Route render={() => <Redirect to="/home" />} />
                            <Redirect from="/" to="/home" />
                        </Switch>
                    </div>
                </div>
            </WithAuthz>
        </div>
    );
}

export default withTranslation()(AppLayout);
