import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import AppLayout from "../components/layout/AppLayout/AppLayout";
import { AuthenticatedComponent } from "../auth/AuthenticatedComponent.hoc";
import ErrorBoundary from "../components/error/ErrorBoundary";
//import history from "./../history";
import { AppProvider } from "./../context/app.context";

function Shell() {
    return (
        <AppProvider>
            <ErrorBoundary>
                <BrowserRouter>
                    <Route
                        render={(props) => (
                            <AuthenticatedComponent {...props}>
                                <AppLayout />
                            </AuthenticatedComponent>
                        )}
                    />
                </BrowserRouter>
            </ErrorBoundary>
        </AppProvider>
    );
}

export default Shell;
