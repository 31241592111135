import { TagManagerArgs } from "react-gtm-module";

declare global {
    interface Window {
        appConfig: any;
        DD_RUM: any;
    }
}

export interface ApiConfig {
    ump: string;
    dam: string;
}

export interface IdentityConfig {
    relyingParty: string;
    url: string;
    client: string;
    redirect: string;
    response: string;
    scope: string;
    logoutUrl: string;
    authorizePath: string;
}
interface ComponentsConfig {
    headerVersion: string;
}
interface ProductsConfig {
    metaData: string;
    translations: string;
}
interface AssetsConfig {
    base: string;
    assetsVersion: string;
    products: ProductsConfig;
}
interface CdnConfig {
    baseUrl: string;
    components: ComponentsConfig;
    assets: AssetsConfig;
}
export interface GTMConfig {
    trackingEnabled: string;
    props: TagManagerArgs;
}
export interface OTCookieConfig {
    domainScriptId: string;
}
export interface RUMConfigProps {
    scriptSrc: string;
    clientToken: string;
    applicationId: string;
    site: string;
    service: string;
    environment: string;
}
export interface MonitoringConfig {
    enabled: string;
    props: RUMConfigProps;
}
export interface AppConfig {
    api: ApiConfig;
    identity: IdentityConfig;
    cdn: CdnConfig;
    portalEnv: string;
    documentationPortalLink: string;
    contactLink: string;
    damApiKey: string;
    supportLink: string;
    analytics: GTMConfig;
    cookieConsent: OTCookieConfig;
    marketingLink: string;
    monitoring: MonitoringConfig;
    appsRetryInterval: number;
}

export const getAppConfig = (): AppConfig => {
    return window.appConfig as AppConfig;
};

export const parseLangCode = (lang?: string): string => {
    if (!lang) lang = "en";
    if (lang.indexOf("-") > -1 || lang.indexOf("_") > -1) {
        [lang] = lang.split(/[_-]+/);
    }
    return lang;
};
