import React, { RefObject, PureComponent } from "react";
import i18n from "../../../../i18n/translation";
import { AppContext } from "../../../../context/app.context";
import { ILocale, LOCALE_LIST } from "./../../../../common/locale";
import { getAppConfig, AppConfig, parseLangCode } from "../../../../common/utils";
import { HeaderEventTypes } from "./Header.action";
import "./Header.scss";
interface IHeaderState {
    isLoading: boolean;
    logoLink: string;
}
interface IHeaderProps {
    headerTitle?: string;
}
//TODO - customevent interface to be referred from header component event detail object interface that need to be exposed from header component and included here
interface IHeaderEventDetail {
    action?: string;
    value?: string;
    source?: string;
}
export class Header extends PureComponent<IHeaderProps, IHeaderState> {
    static contextType = AppContext;
    public headerElementRef: RefObject<HTMLElement>;
    public config: AppConfig = getAppConfig();
    public constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            isLoading: true,
            logoLink: this.config.marketingLink,
        };
        this.headerElementRef = React.createRef();
    }
    /**
     * OnMount we will call load script to load common header component reference from config.js file
     */
    public componentDidMount() {
        const COMPONENT_BASE_PATH = `components/ttl-header/${this.config.cdn.components.headerVersion}/`;
        const componentScriptFiles: string[] = [
            `${COMPONENT_BASE_PATH}vendor/custom-elements-es5-adapter.js`,
            `${COMPONENT_BASE_PATH}vendor/webcomponents-bundle.js`,
            `${COMPONENT_BASE_PATH}ttl-header.js`,
        ];
        this.loadRefScripts(componentScriptFiles).then(() => {
            this.setState({ isLoading: false }, () => {
                this.initComponent();
            });
        });
    }
    /**
     * Method to create script element tag and include header component reference in head tag
     */
    public loadRefScripts(files: string[]) {
        return new Promise((resolve) => {
            const HEAD_TAG = document.getElementsByTagName("head")[0];
            files.forEach((file) => {
                const SCRIPT_ELE = document.createElement("script");
                SCRIPT_ELE.src = `${this.config.cdn.baseUrl}${file}`;
                SCRIPT_ELE.type = "text/javascript";
                HEAD_TAG.appendChild(SCRIPT_ELE);
            });
            resolve([]);
        });
    }
    /**
     * Remove event listeners on destroy
     */
    public componentWillUnmount() {
        if (this.headerElementRef?.current) {
            this.headerElementRef.current.removeEventListener(
                HeaderEventTypes.CUSTOM_EVENT_NAME,
                this.onHeaderItemClickHandler,
            );
        }
    }
    /**
     * Method to initialise component and register event listener
     */
    initComponent() {
        if (this.headerElementRef?.current) {
            this.headerElementRef.current.addEventListener(
                HeaderEventTypes.CUSTOM_EVENT_NAME,
                this.onHeaderItemClickHandler,
            );
        }
        this.getLogoLink();
    }
    /**
     * Method to handle events from header component
     * Event is typecasted to CustomEvent
     * @param event Event
     */
    onHeaderItemClickHandler = (event: Event) => {
        try {
            const customHeaderEvent: CustomEvent = event as CustomEvent;
            const eventDetail: IHeaderEventDetail = customHeaderEvent.detail as IHeaderEventDetail;
            const eventAction: string = eventDetail?.action as string;
            if (eventAction === HeaderEventTypes.TOGGLE_NAV) {
                console.log("Toggle nav");
            } else if (eventAction === HeaderEventTypes.LANGUAGE_CHANGED) {
                let selectedLanguage: string = eventDetail && eventDetail.value ? eventDetail.value : "en";
                try {
                    localStorage.setItem("language", selectedLanguage);
                    selectedLanguage = parseLangCode(selectedLanguage);
                    this.context.state.user.language = selectedLanguage;
                    i18n.changeLanguage(selectedLanguage);
                    this.getLogoLink();
                } catch (error) {
                    console.log(error);
                }
            } else if (eventAction === HeaderEventTypes.LOG_OUT) {
                this.context.state &&
                    this.context.state.authService &&
                    this.context.state.authService.logout(this.context.state.user.idToken);
            }
        } catch (error) {
            console.log(error);
        }
    };
    getLogoLink() {
        const DEFAULT_LANG = "en";
        let lang = this.context.state?.user?.language || DEFAULT_LANG;
        lang = parseLangCode(lang);
        const SELECTED_LOCALE: any = LOCALE_LIST.find((locale: ILocale) => {
            return locale.value === lang;
        });
        this.setState({ logoLink: `${this.config.marketingLink}${SELECTED_LOCALE?.docValue || DEFAULT_LANG}` });
    }
    public render() {
        return (
            <header className="top-bar">
                {this.state.isLoading ? null : (
                    <ttl-header
                        ref={this.headerElementRef}
                        header-title={this.props.headerTitle}
                        locale-list={JSON.stringify(LOCALE_LIST)}
                        language={this.context.state.user.language}
                        user={JSON.stringify(this.context.state.user)}
                        logo-link={this.state.logoLink}
                        env={this.config.portalEnv}
                        access-token={this.context.state.accessToken}
                    ></ttl-header>
                )}
            </header>
        );
    }
}

export default Header;
