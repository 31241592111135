/**
 * Higher-order-component to check whether user is logged in and provide access to further part of the app.
 * If session doesn't exists, then will redirect to identity service and on callback will assign token in localstorage
 */
import React, { Component } from "react";
import { getAppConfig } from "./../common/utils";
import Preloader from "../components/elements/atom/Preloader/Preloader";
import { RouteComponentProps } from "react-router-dom";
import { AppContext } from "../context/app.context";
import { SET_ACCESS_TOKEN, SET_AUTH_SERVICE } from "../context/action";
import AuthService from "./Authentication";

interface IState {
    isLoggedIn?: boolean;
}

interface TidParams {
    access_token?: string;
    state?: string;
    token_type?: string;
    expires_in?: string;
    session_state?: string;
}
interface IProps extends RouteComponentProps {
    access_token?: string;
    state?: string;
    expires_in?: string;
    session_state?: string;
    token_type?: string;
}
class AuthenticatedComponent extends Component<IProps, IState> {
    public config = getAppConfig();
    public authService: AuthService;
    public state: IState = {
        isLoggedIn: false,
    };
    static contextType = AppContext;
    constructor(props: IProps) {
        super(props);
        this.authService = new AuthService();
    }
    /**
     * Check user logged in status and set isLoggedIn to true or false. On error, we will redirect to  TID login
     */
    public componentDidMount() {
        this.isLoggedIn()
            .then((res: { accessToken?: string; redirectTo?: string }) => {
                this.context.dispatch({ type: SET_AUTH_SERVICE, authService: this.authService });
                this.context.dispatch({ type: SET_ACCESS_TOKEN, accessToken: res.accessToken });
                this.setState({ isLoggedIn: true });
                if (res.redirectTo) {
                    this.props.history.push(res.redirectTo);
                }
            })
            .catch(() => {
                this.authService.login();
            });
    }
    /**
     * Checks application localstorage of app-access-token and resolve the promise.
     * If it is a callback from TID, we will have access_token as part of url query params and will handle it here and redirect to application
     */
    public isLoggedIn() {
        return new Promise<{ accessToken?: string; redirectTo?: string }>(async (resolve, reject) => {
            const token = this.context.state.accessToken;
            if (token) {
                resolve({ accessToken: token });
            } else {
                const isCallback =
                    window.location.href.indexOf("access_token") !== -1 || window.location.href.indexOf("code") !== -1;
                if (isCallback) {
                    try {
                        const user = await this.authService.userManager.signinCallback();
                        const accessToken = user?.access_token;
                        if (accessToken) {
                            if (user?.state) {
                                resolve({
                                    accessToken: accessToken,
                                    redirectTo: decodeURIComponent(user.state),
                                });
                            } else {
                                window.history.replaceState(
                                    null,
                                    "",
                                    `${window.location.protocol}//${window.location.host}`,
                                );
                                resolve({ accessToken: accessToken });
                            }
                        } else {
                            reject();
                        }
                    } catch (error) {
                        reject();
                    }
                } else {
                    reject();
                }
            }
        });
    }
    public render() {
        if (this.state.isLoggedIn === true) {
            return <>{this.props.children}</>;
        }
        return <Preloader />;
    }
}

export { AuthenticatedComponent };
