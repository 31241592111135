import React, { useEffect, useState, useContext } from "react";
import { AxiosResponse } from "axios";
import collection from "lodash";
import CardLayout from "../../layout/CardLayout/CardLayout";
import Banner from "../../elements/organisms/Banner/Banner";
import bannerImage from "../../../assets/images/banner.jpg";
import { AppConfig, getAppConfig, parseLangCode } from "../../../common/utils";
import { IAioApps, SkeletonCardData, IUmpErrResponse } from "../../../common/models";
import { API_PATH, ERROR_CODE } from "../../../common/constants";
import { AppService, FetchService } from "../../../services/app.services";
import { AppContext } from "../../../context/app.context";
import "./Home.scss";
import i18n from "../../../i18n/translation";
interface BannerLocaleImages {
    [index: string]: string[];
}
const Home = () => {
    const [aioAppsData, setAioAppsData] = useState([] as IAioApps[]);
    const [aioUserAppsData, setAioUserAppsData] = useState([] as IAioApps[]);
    const [errorState, setErrorState] = useState({} as AxiosResponse<IUmpErrResponse>);
    const [apiLoadingStatus, setApiLoadingStatus] = useState(true);
    const [bannerLoadingStatus, setBannerLoadingStatus] = useState(true);
    const [bannerImages, setBannerImages] = useState([] as string[]);
    const config: AppConfig = getAppConfig();
    const appContext = useContext(AppContext);
    let bannerLocaleImgs: BannerLocaleImages = {};
    let selectedLanguage: string;
    let appsTimeoutRef: any = null;
    /**
     * Method to Fetch apps - public or user specific apps from UMP. Included retry argument to handle an
     * use case after user invitation / registration flow from UMP. When retry is true, we will delay the next
     * call after few milliseconds and proceed with the response with data or empty state
     * @function getAppsList
     * @returns Array of apps
     */
    const getAppsList = (requestURL: string, retry = false) => {
        return AppService.axios
            .get(requestURL)
            .then((result) => {
                return result && result.data && result.data.elements ? result.data.elements : [];
            })
            .catch(async (err) => {
                if (err?.status === ERROR_CODE.FORBIDDEN && retry) {
                    await new Promise((resolve) => {
                        appsTimeoutRef = setTimeout(async () => {
                            const appsList: IAioApps[] = ((await getAppsList(requestURL)) as IAioApps[]) || [];
                            resolve(appsList);
                        }, config.appsRetryInterval || 3000);
                    });
                }
                return [];
            });
    };
    const getProductMetadataUrl = () => {
        return `${config.cdn.baseUrl}${config.cdn.assets.base}${config.cdn.assets.assetsVersion}/${config.cdn.assets.products.metaData}`;
    };
    const getMetaDataDetails = (reqURL: string) => {
        return AppService.axios
            .get(reqURL)
            .then((result) => {
                return result && result.data ? result.data : {};
            })
            .catch((err) => {
                console.log(err);
                return {};
            });
    };
    /**
     * Method to retrieve "en" banner images which will be considered as fallback images for other languages
     * If default images also empty, then we will assign placeholder image
     */
    const getDefaultBannerImages = () => {
        fetchBannerData("en")
            .then((result: any) => {
                let defaultBanners = (result.data?.images as string[]) || [];
                defaultBanners = defaultBanners.map((banner: any) => {
                    return banner.url;
                });
                bannerLocaleImgs["en"] = defaultBanners;
                if (defaultBanners.length === 0) {
                    defaultBanners = [bannerImage];
                }
                setBannerImages(defaultBanners);
                setBannerLoadingStatus(false);
            })
            .catch((error) => {
                console.log(error);
                setBannerImages([bannerImage]);
                setBannerLoadingStatus(false);
            });
    };
    /**
     * Method to check local array and trigger language specific banners
     * If selected language contains the banner images, then local array will be updated and set state banner images
     * If no images, then we will load default 'en' images
     * @param locale string
     */
    const getBannerImages = (locale = "en") => {
        if (bannerLocaleImgs[locale] && bannerLocaleImgs[locale].length > 0) {
            setBannerImages(bannerLocaleImgs[locale]);
        } else {
            setBannerLoadingStatus(true);
            fetchBannerData(locale)
                .then((result: any) => {
                    let banners = (result.data?.images as string[]) || [];
                    banners = banners.map((banner: any) => {
                        return banner.url;
                    });
                    bannerLocaleImgs[locale] = banners || [];
                    if (banners.length === 0 && bannerLocaleImgs["en"]) {
                        banners = bannerLocaleImgs["en"];
                    }
                    setBannerImages(banners);
                    if (banners.length === 0) {
                        getDefaultBannerImages();
                    }
                    setBannerLoadingStatus(false);
                })
                .catch((err) => {
                    console.log(err);
                    setBannerImages([bannerImage]);
                    setBannerLoadingStatus(false);
                });
        }
    };
    /**
     * Method to trigger DAM API to render banner images based on the languge and other default parameters
     * Included FetchService instance to avoid header assignment which causes CORS exception
     */
    async function fetchBannerData(locale: string) {
        return FetchService.axios.get(
            `${config.api.dam}?key=${config.damApiKey}&lng=${locale}&format=banner&size=big&tag=AiO`,
        );
    }
    const loadProductTranslations = async (lang: string) => {
        const locale = lang || "en";
        if (selectedLanguage !== locale) {
            const metaDataTranslations = await getMetaDataDetails(
                `${getProductMetadataUrl()}${config.cdn.assets.products.translations}${locale}.json`,
            );
            i18n.addResourceBundle(locale, "metaData", metaDataTranslations);
            selectedLanguage = locale;
            i18n.changeLanguage(locale);
            getBannerImages(locale);
        }
    };
    /**
     * Async function that initiates API request to fetch all apps and apps subscribed by the user.
     * Trigger call to load product metdata properties json
     * Once fetched, assign metadata property to respective product
     * Sort the apps list based on rank property under metadata
     * Seggregate apps list to be displayed under explore more section
     * Fetch product properties translation files
     * Assign the apps into usersection and otherssection only if the name exists in the metadata list
     */
    const fetchAppsData = async () => {
        const locale = parseLangCode(appContext.state?.user?.language);
        try {
            const allAioAppsRes: IAioApps[] =
                ((await getAppsList(`${config.api.ump}/${API_PATH.UMP.ALL_PRODUCTS}`)) as IAioApps[]) || [];
            const userApps: IAioApps[] =
                ((await getAppsList(`${config.api.ump}/${API_PATH.UMP.USER_PRODUCTS}`, true)) as IAioApps[]) || [];
            let otherApps: IAioApps[] = [];
            let userFilteredApps: IAioApps[] = [];
            const prodMetaData: any = await getMetaDataDetails(
                `${getProductMetadataUrl()}properties/${config.portalEnv}/properties.json`,
            );
            allAioAppsRes.forEach((appData: IAioApps) => {
                if (prodMetaData[appData.name]) {
                    appData.hasAccess = false;
                    appData.metaData = prodMetaData[appData.name];
                    const appExistsInList: any = userApps.find((app: IAioApps) => {
                        return app.name === appData.name;
                    });
                    if (!appExistsInList && !appData.metaData.private) {
                        otherApps.push(appData);
                    }
                }
            });
            userApps.forEach((appData: IAioApps) => {
                if (prodMetaData[appData.name]) {
                    appData.hasAccess = true;
                    appData.metaData = prodMetaData[appData.name];
                    userFilteredApps.push(appData);
                }
            });
            userFilteredApps = collection.sortBy(userFilteredApps, ["metaData.rank"]);
            otherApps = collection.sortBy(otherApps, ["metaData.rank"]);
            loadProductTranslations(locale);
            setAioUserAppsData(userFilteredApps);
            setAioAppsData(otherApps);
            getBannerImages(locale);
        } catch (error) {
            if (!error) {
                error = { status: "-1", data: { message: "Unable to load apps" } };
            }
            setErrorState(error);
        } finally {
            setApiLoadingStatus(false);
        }
    };

    /**
     * Function that triggers the functions, which fetches data required for the initial component load.
     */
    const initBaseDataFetch = () => {
        setApiLoadingStatus(true);
        setAioUserAppsData(SkeletonCardData);
        fetchAppsData();
    };
    useEffect(() => {
        bannerLocaleImgs = {};
        i18n.on("languageChanged", loadProductTranslations);
        initBaseDataFetch();
        return () => {
            i18n.off("languageChanged", loadProductTranslations);
            appsTimeoutRef && clearTimeout(appsTimeoutRef);
        };
    }, []);

    if (!errorState.status) {
        return (
            <>
                <Banner
                    bannerImages={bannerImages}
                    isApiLoading={bannerLoadingStatus}
                    data-test="component-banner"
                ></Banner>
                {!apiLoadingStatus && aioUserAppsData.length === 0 ? (
                    <div className="emptyMsg">
                        <p
                            className="cardLayout"
                            dangerouslySetInnerHTML={{
                                __html: i18n.t("TTM.apps.userAppsEmptyMessage", {
                                    supportLink: config.supportLink,
                                }),
                            }}
                        ></p>
                    </div>
                ) : (
                    <CardLayout
                        aioAppsData={aioUserAppsData}
                        data-test="card-layout"
                        isApiLoading={apiLoadingStatus}
                    ></CardLayout>
                )}
                {!apiLoadingStatus && aioAppsData && aioAppsData.length > 0 ? (
                    <div>
                        <hr className="lineSeparator" />
                        <h3 className="sectionTitle cardLayout">{i18n.t("TTM.apps.exploreMore")}</h3>
                        <CardLayout
                            aioAppsData={aioAppsData}
                            data-test="card-layout"
                            isApiLoading={apiLoadingStatus}
                        ></CardLayout>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    } else {
        return (
            <div className="alignCenter shadow-1 m-5 p-5 text-center">
                <p>{errorState && errorState.data && errorState.data.message}</p>
            </div>
        );
    }
};

export default Home;
