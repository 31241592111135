import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        fallbackLng: "en",
        lng: "en", //Based on user default language, we need to assign the value here. Need to check whether it need to wait until user profile is loaded
        debug: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        // react already safes from xss
        interpolation: { escapeValue: false },
        react: {
            wait: true,
            useSuspense: false,
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`,
        },
    });

export default i18n;
