import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/app.context";
import { parseLangCode } from "../../../../common/utils";
import "./Dropdown.scss";
import { LOCALE_LIST, ILocale } from "../../../../common/locale";

export interface IDropdownItem {
    displayName: string;
    onAction?: () => void;
    clickable: boolean;
    link?: string;
}

export interface DropdownProps {
    dropDownItems: IDropdownItem[];
    parentIcon: string;
}

function Dropdown(props: DropdownProps) {
    const { t } = useTranslation();
    const [_isOpen, setIsOpen] = useState(false);
    const dropdownEl = useRef<HTMLDivElement>(null);
    const appContext = useContext(AppContext);
    const toggleOpen = () => {
        setIsOpen(!_isOpen);
    };
    const navigateToLink = (url: string) => {
        const DEFAULT_LANG = "en";
        let lang = appContext.state?.user?.language || DEFAULT_LANG;
        lang = parseLangCode(lang);
        const SELECTED_LOCALE: any = LOCALE_LIST.find((locale: ILocale) => {
            return locale.value === lang;
        });
        url = url.replace("{{lang}}", SELECTED_LOCALE?.docValue || DEFAULT_LANG);
        window.open(url, "_blank", "noreferrer");
    };
    const hideDropdown = () => {
        setIsOpen(false);
    };

    const handleOnBlur = () => {
        hideDropdown();
    };

    /**
     * Listen for dropdown visibility and enable focus on the element explicitly.
     */
    useEffect(() => {
        if (_isOpen && dropdownEl && dropdownEl.current) {
            dropdownEl.current.focus();
        }
    }, [_isOpen]);

    return (
        <div data-test="component-dropdown" className="dropdown">
            <button
                data-test="dropdown-button"
                onClick={toggleOpen}
                className={`btn btn-md btn-icon-only btn-text-primary ${_isOpen ? "open" : "closed"}`}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i className="modus-icons">{props.parentIcon}</i>
            </button>
            <div
                data-test="dropdown-options"
                onBlur={handleOnBlur}
                ref={dropdownEl}
                tabIndex={-1}
                className={`dropdown-menu p-0 shadow-2 shadow-white position-absolute dropdown-menu-right ${
                    _isOpen ? "show" : ""
                }`}
            >
                {props.dropDownItems.map((item: IDropdownItem, index: number) => (
                    <div
                        data-test="dropdown-option-item"
                        onClick={() => (item.link ? navigateToLink(item.link) : () => false)}
                        key={index}
                        className={`dropdown-item ${item.clickable ? "clickable" : ""}`}
                    >
                        {t(`metaData:${item.displayName}`)}
                    </div>
                ))}
            </div>
        </div>
    );
}
export default Dropdown;
