import React from "react";
import { IAioApps, IApiStatus } from "../../../common/models";
import AppCard from "../../elements/organisms/AppCard/AppCard";
import "./CardLayout.scss";

export interface CardLayoutProps {
    aioAppsData: IAioApps[];
}

function CardLayout(props: CardLayoutProps & IApiStatus) {
    return (
        <section data-test="card-layout" className="cardLayout mt-4 flex-row d-flex flex-wrap">
            {props.aioAppsData.map((app: IAioApps, index: number) => (
                <AppCard
                    data-test="component-appCard"
                    key={index}
                    appData={app}
                    isApiLoading={props.isApiLoading}
                ></AppCard>
            ))}
        </section>
    );
}

export default CardLayout;
