import React, { createContext, useReducer } from "react";
import { AppState } from "./app.model";
import { User } from "./user.model";
import appReducer from "./app.reducer";
import AuthService from "../auth/Authentication";

export const initAppState: AppState = {
    isLoggedIn: false,
    user: {} as User,
    accessToken: "",
    authService: {} as AuthService,
};

export interface IStateContext {
    state: AppState;
    dispatch: () => null;
}
const AppContext = createContext<{ state: AppState; dispatch: React.Dispatch<any> }>({} as IStateContext);

const AppProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(appReducer, initAppState);
    //console.log("app context provider", state);
    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
export { AppContext, AppProvider };
